<template>
  <div>
    <el-checkbox-group
      v-model="genders"
      :disabled="disabled"
    >
      <el-checkbox-button
        v-for="gender in sexList"
        :key="gender.key"
        :label="gender.key"
        >{{ gender.name }}</el-checkbox-button
      >
    </el-checkbox-group>
  </div>
</template>
<script>
import { sexList } from '@/views/google/create/index';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    genders: {
      get() {
        return [...this.value];
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  data() {
    return {
      sexList,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox-button.is-disabled.is-checked .el-checkbox-button__inner {
  background: #a0cfff;
  color: #fff;
}
::v-deep .el-checkbox-button.is-disabled .el-checkbox-button__inner {
  background: #f5f7fa;
  color: #c0c4cc;
}
</style>
