<template>
  <div>
    <el-dialog
      title="创建自定义细分受众"
      :visible="createAudienceDialogVisible"
      width="800px"
      append-to-body
      @close="close"
      @opened="openInit"
    >
      <el-form
        :model="form"
        label-width="200px"
        label-suffix="："
        :rules="rules"
        ref="form"
      >
        <el-form-item
          label="自定义细分受众名称"
          prop="title"
        >
          <el-input
            v-model="form.title"
            placeholder="受众名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="有兴趣或行为的用户"
          prop="keywords"
        >
          <el-radio-group v-model="form.type">
            <el-radio
              :label="item.key"
              v-for="item in userTypeOptions"
              :key="item.key"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
          <el-input
            v-model="form.keywords"
            placeholder="添加兴趣或购买意向,多个用英文逗号分隔"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="浏览特定网站的用户"
          prop="domain"
        >
          <div>
            <el-tag
              v-for="(tag, index) in domainTags"
              :key="index"
              closable
              @close="deleteTags(index)"
              class="m-1"
              >{{ tag }}</el-tag
            >
          </div>
          <el-input
            v-model="domain"
            placeholder="添加网址,回车即可添加"
            :disabled="domainCheckLoading"
            @keyup.enter.native="addDomainTag"
          ></el-input>
        </el-form-item>
        <!--  <el-form-item label="使用特定应用的用户">
          <el-input
            v-model="form.name"
            placeholder="添加应用/在Google play谷歌商店中搜索要添加的应用"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { createCustomerAudience, checkUrl } from '@/api/google/create';
const userTypeOptions = [
  { key: 'PURCHASE_INTENT', name: '具有任何此类兴趣或购买意向的用户' },
  { key: 'SEARCH', name: '曾在Google上搜索任意这些字词的用户' },
];
export default {
  props: {
    createAudienceDialogVisible: {
      type: Boolean,
      default: false,
    },
    currentAccount: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        title: '',
        type: 'PURCHASE_INTENT',
        keywords: '',
      },
      userTypeOptions,
      domainTags: [],
      domain: '',
      rules: {
        title: [{ required: true, message: '受众名称必填' }],
        keywords: [{ required: true, message: '兴趣词不能为空' }],
      },
      domainCheckLoading: false,
    };
  },
  methods: {
    close() {
      this.$emit('update:createAudienceDialogVisible', false);
    },
    openInit() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.domainTags = [];
        this.domain = '';
      });
    },
    async addDomainTag(v) {
      const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(?:\/[^\s]*)?$/;
      if (!domainRegex.test(this.domain)) {
        this.$message({
          type: 'warning',
          message: '请检查域名格式',
        });
        return;
      }
      if (this.domainTags.includes(this.domain)) {
        this.$message({
          type: 'warning',
          message: '请不要添加重复域名',
        });
        return;
      }
      try {
        this.domainCheckLoading = true;
        const res = await checkUrl({ url: this.domain });
        this.domainCheckLoading = false;
        if (res.code == 0) {
          this.domainTags.push(this.domain);
          this.domain = '';
        }
      } catch (error) {}
    },
    deleteTags(index) {
      this.domainTags.splice(index, 1);
    },
    sure() {
      this.$refs.form.validate((validator) => {
        if (validator) {
          let params = {
            accountId: this.currentAccount.id,
            ...this.form,
            keywords: this.form.keywords.split(','),
            urls: this.domainTags,
          };
          this.$showLoading();
          createCustomerAudience(params).then((res) => {
            this.$hideLoading();
            this.$emit('update:createAudienceDialogVisible', false);
            this.$emit('getList');
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
