<template>
  <div class="audienceSelect">
    <el-select
      placeholder="选择受众群体"
      value-key="id"
      v-model="currentGroup"
      clearable
      filterable
      @change="selectAudienceGroup"
      v-loading="loading"
    >
      <el-option
        v-for="item in audienceGroupList"
        :key="item.id"
        :label="item.name"
        :value="item.resourceName"
      >
        <div>
          <div class="bold flex justify-between items-center">
            <div>{{ item.name }}</div>
            <div>
              <el-button
                type="text"
                @click.stop="createAudienceGroupEvent('edit', item)"
                icon="el-icon-edit"
              ></el-button>
              <el-button
                type="text"
                @click.stop="createAudienceGroupEvent('copy', item)"
                icon="el-icon-document-copy"
              ></el-button>
            </div>
          </div>
          <div class="text-[#999] text-[12px] leading-5">
            <p>年龄：{{ getAge(item) }}</p>
            <p>性别：{{ getGender(item) }}</p>
            <p v-if="getAudience(item)">自定义受众：{{ getAudience(item) }}</p>
            <p v-if="getInterestWord(item)">兴趣词：{{ getInterestWord(item) }}</p>
          </div>
        </div>
      </el-option>
    </el-select>
    <el-button
      type="text"
      icon="el-icon-refresh"
      class="w-[60px]"
      @click="getAudienceGroupList"
    ></el-button>
    <div>
      <el-button
        type="text"
        icon="el-icon-plus"
        @click="createAudienceGroupEvent('create')"
        >创建受众群体</el-button
      >
    </div>
    <CreateAudienceGroup
      :createAudienceGroupDialogVisible.sync="createAudienceGroupDialogVisible"
      :currentAccount="currentAccount"
      :currentAudience="currentAudience"
      :type="type"
      @getAudienceGroupList="getAudienceGroupList"
    />
  </div>
</template>
<script>
import { accountAudiences } from '@/api/google/create';
import { sexList, interestList } from '@/views/google/create/index.js';
import CreateAudienceGroup from '@/views/google/create/components/audience/CreateAudienceGroup/index';
export default {
  props: {
    value: {
      type: String,
      default: () => '',
    },
    accountId: {
      type: [Number, String],
      default: null,
    },
    defaultList: {
      type: Array,
      default: () => [],
    },
    currentAccount: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      audienceGroupList: [],
      currentGroup: '',
      sexList,
      interestList,
      createAudienceGroupDialogVisible: false,
      type: 'create',
      initAudience: {
        ageRange: {
          minAge: 18,
          maxAge: null,
          undetermined: true,
        }, //年龄
        genders: ['UNDETERMINED', 'MALE', 'FEMALE'], // 性别
        interests: [], // 兴趣词
        customAudience: [], // 自定义受众
        resourceName: '', //资源名称
      },
      currentAudience: {},
      loading: false,
    };
  },
  components: {
    CreateAudienceGroup,
  },
  methods: {
    /* 获取受众分组列表 */
    async getAudienceGroupList(v) {
      this.loading = true;
      await accountAudiences({ accountId: this.accountId })
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            this.audienceGroupList = res.data.map((v) => {
              return {
                ...v,
                id: v.id + '',
              };
            });
            if (v && typeof v == 'string') {
              this.currentGroup = v;
              this.selectAudienceGroup(v);
            }
          }
        })
        .finally((res) => {
          this.loading = false;
        });
    },
    /* 选择受众分组导出事件 */
    selectAudienceGroup(v) {
      this.$nextTick(() => {
        this.$emit('input', v);
        this.$emit(
          'selectAudienceGroup',
          this.audienceGroupList.find((item) => item.resourceName == v),
        );
      });
    },
    /** 展示年龄 */
    getAge(audience) {
      let age = `${audience.ageRange.minAge} ~ ${audience.ageRange.maxAge || '65+'}`;
      if (audience.undetermined) {
        return `${age},未知`;
      } else {
        return age;
      }
    },
    /** 展示性别 */
    getGender(audience) {
      return this.sexList
        .filter((sex) => audience.genders.includes(sex.key))
        .map((v) => v.name)
        .join(',');
    },
    /**展示自定义受众 */
    getAudience(audience) {
      let list =
        audience.customAudiences && audience.customAudiences.length ? audience.customAudiences.map((v) => v.title) : [];
      let length = list.length;
      if (!length) {
        return '';
      }
      return length > 2 ? list.slice(0, 2).join(',') + `等${length}个` : list.join(',');
    },
    // 查找name
    getTreeName(nodes, idArray) {
      let names = [];
      nodes.forEach((node) => {
        if (idArray.includes(node.id)) {
          names.push(node.name);
        }
        if (node.children) {
          this.getTreeName(node.children, idArray);
        }
      });
      return names;
    },
    /** 展示兴趣词 */
    getInterestWord(audience) {
      let list = this.getTreeName(interestList, audience.userInterests || []);
      return length > 2 ? list.slice(0, 2).join(',') + `等${length}个` : list.join(',');
    },
    /** 点击创建受众组 */
    createAudienceGroupEvent(type, currentAudience) {
      this.type = type;
      if (type !== 'create') {
        this.currentAudience = {
          ...this.initAudience,
          ...currentAudience,
          name: type == 'copy' ? currentAudience.name + '_copy' : currentAudience.name,
        };
      } else {
        this.currentAudience = { ...this.initAudience };
      }

      this.createAudienceGroupDialogVisible = true;
    },
  },
  watch: {
    value: {
      handler() {
        this.currentGroup = this.value;
      },
      immediate: true,
    },
    defaultList: {
      handler() {
        this.audienceGroupList = this.defaultList;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.getAudienceGroupList();
  },
};
</script>
<style lang="scss" scoped>
.el-select-dropdown__item {
  height: auto;
}
</style>
