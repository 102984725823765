<template>
  <div class="ageWrap">
    <div class="flex">
      <!-- <el-form-item prop="minAge"> -->
      <el-select
        v-model="formData.minAge"
        @change="(val) => ageChange('minAge', val)"
        :disabled="disabled"
        clearable
      >
        <el-option
          v-for="age in minAgeList"
          :label="age.name"
          :key="age.name"
          :value="age.key"
        ></el-option>
      </el-select>
      <!-- </el-form-item> -->

      <div class="mx-2 w-[2%]">~</div>
      <!-- <el-form-item prop="maxAge"> -->
      <el-select
        v-model="formData.maxAge"
        @change="(val) => ageChange('maxAge', val)"
        :disabled="disabled"
        clearable
      >
        <el-option
          v-for="age in maxAgeList"
          :label="age.name"
          :key="age.name"
          :value="age.key"
        ></el-option
      ></el-select>
      <el-checkbox
        class="ml-2"
        label="未知"
        v-model="formData.undetermined"
        :disabled="disabled"
      ></el-checkbox>
    </div>
  </div>
</template>
<script>
import { maxAgeList, minAgeList } from '../../../index.js';
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          minAge: 18,
          maxAge: null,
          undetermined: true,
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxAgeList,
      minAgeList,
      formData: {},
    };
  },
  methods: {
    /**
     * 选择年龄
     * @param {*} key
     * @param {*} val
     */
    ageChange(key, val) {
      switch (key) {
        case 'minAge':
          if (val > this.formData.maxAge) {
            this.formData.maxAge = this.minAgeList.find((v) => v.key == val).max;
          }
          break;
        case 'maxAge':
          if (val < this.formData.minAge) {
            this.formData.minAge = this.maxAgeList.find((v) => v.key == val).min;
          }
      }
      console.log(this.emitData());
      this.$emit('input', { ...this.emitData() });
    },
    emitData() {
      return { ...this.formData, maxAge: this.formData.maxAge === 100 ? null : this.formData.maxAge };
    },
  },
  watch: {
    value: {
      handler() {
        this.formData = {
          minAge: this.value.minAge,
          maxAge: this.value.maxAge ? this.value.maxAge : 100,
          undetermined: this.value.undetermined,
        };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.ageWrap .el-select {
  width: 20%;
}
</style>
