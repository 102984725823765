<template>
  <div>
    <el-dialog
      :title="`${title}受众群体`"
      :visible="createAudienceGroupDialogVisible"
      width="1200px"
      append-to-body
      @close="close"
      @opened="openInit"
    >
      <el-form
        :model="form"
        label-width="200px"
        label-suffix="："
        :rules="rules"
        ref="form"
        class="createWrap"
      >
        <el-form-item
          label="受众资源名称"
          prop="name"
        >
          <el-input
            v-model="form.name"
            placeholder="请输入受众资源的名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="性别"
          prop="sex"
        >
          <AudienceSex v-model="form.genders" />
        </el-form-item>
        <el-form-item label="年龄">
          <AudienceAge v-model="form.ageRange" />
        </el-form-item>
        <el-form-item label="自定义细分受众">
          <CustomAudience
            ref="customAudience"
            :currentAccount="currentAccount"
            :defaultList="constData.customAudienceList"
            v-model="form.customAudiences"
          />
        </el-form-item>
        <el-form-item label="兴趣和详细受众">
          <InterestAudience
            ref="interestAudience"
            v-model="form.userInterests"
            :list="interestList"
            :interestLabelSaveAPI="interestLabelSaveAPI"
            :defaultList="constData.interestLabelQueryList"
            :accountId="currentAccount?.id"
            :defaultExpandAll="false"
            :disabled="false"
            @input="(v) => getInterest(v)"
          ></InterestAudience>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <CheckAudienceGroup
      :checkAudienceShow.sync="checkAudienceShow"
      :checkAudienceAdsList="checkAudienceAdsList"
      :audienceName="form.name"
      @createAudience="createAudience"
    />
  </div>
</template>
<script>
import { saveCommonInterests, createOrUpdateAudience, getUsedAdInfos } from '@/api/google/create';
import { sexList, interestList } from '../../../index';
import InterestAudience from '@/views/google/create/components/audience/interestAudiences/index';
import AudienceAge from '@/views/google/create/components/audience/AudienceAge/index.vue';
import AudienceSex from '@/views/google/create/components/audience/AudienceSex/index.vue';
import CustomAudience from '@/views/google/create/components/audience/CustomAudience/index';
import CheckAudienceGroup from '@/views/google/create/components/audience/CheckAudienceGroup/index';
export default {
  props: {
    createAudienceGroupDialogVisible: {
      type: Boolean,
      default: false,
    },
    currentAccount: {
      type: Object,
      default: () => {},
    },
    constData: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    currentAudience: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    InterestAudience,
    AudienceAge,
    AudienceSex,
    CustomAudience,
    CheckAudienceGroup,
  },
  data() {
    return {
      form: {
        ageRange: {
          minAge: 18,
          maxAge: null,
          undetermined: true,
        }, //年龄
        genders: ['UNDETERMINED', 'MALE', 'FEMALE'], // 性别
        interests: [], // 兴趣词
        customAudience: [], // 自定义受众
        resourceName: '', //资源名称
        name: '',
      },
      interestList,
      sexList, //性别遍历数据
      interestLabelSaveAPI: saveCommonInterests,
      rules: {
        name: [{ required: true, message: '受众群体名称必填' }],
      },
      checkAudienceShow: false,
      checkAudienceAdsList: [],
      createParams: {},
    };
  },
  computed: {
    title() {
      if (this.type == 'edit') {
        return '编辑';
      }
      if (this.type == 'copy') {
        return '复制';
      }
      if (this.type == 'create') {
        return '创建';
      }
    },
  },
  methods: {
    async openInit() {
      this.form = {
        ...this.currentAudience,
        resourceName: this.type == 'copy' ? '' : this.currentAudience.resourceName,
      };
      this.$refs.form.clearValidate();
      await this.$refs.interestAudience?.getInterestLabelQueryList();
      await this.$refs.customAudience?.getCustomAudienceList();
    },
    close() {
      this.form = {};
      this.$emit('update:createAudienceGroupDialogVisible', false);
    },
    createAudience(params) {
      createOrUpdateAudience({ ...this.createParams, ...params }).then((res) => {
        this.$hideLoading();
        this.$emit('update:createAudienceGroupDialogVisible', false);
        this.$emit('getAudienceGroupList', res.data.googleAudience.resourceName);
      });
    },
    getInterest() {},
    sure() {
      this.$refs.form.validate(async (validator) => {
        if (validator) {
          let params = {
            accountId: this.currentAccount.id,
            ...this.form,
          };
          this.createParams = { ...params };
          this.$showLoading();
          if (this.type == 'edit') {
            let res = await getUsedAdInfos(params);
            console.log(res);
            // return;
            if (res.data && res.data.length) {
              this.checkAudienceShow = true;
              this.checkAudienceAdsList = res.data;
              this.$hideLoading();
            } else {
              this.createAudience();
            }
          } else {
            this.createAudience();
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
