<template>
  <div>
    <el-checkbox
      v-model="formData.optimizedTargetingEnabled"
      @change="(v) => change(v, true)"
      >使用优化型定位设置</el-checkbox
    >
    <el-checkbox
      v-model="formData.excludeDemographicExpansion"
      v-show="formData.optimizedTargetingEnabled"
      @change="(v) => change(v, false)"
      >仅向符合我的年龄和性别要求的用户展示广告</el-checkbox
    >
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        optimizedTargetingEnabled: true,
        excludeDemographicExpansion: false,
      },
    };
  },
  methods: {
    change(v, flag) {
      if (flag) this.formData.excludeDemographicExpansion = false;
      this.$emit('input', { ...this.formData });
    },
  },
  watch: {
    value() {
      this.formData = {
        optimizedTargetingEnabled: this.value.optimizedTargetingEnabled,
        excludeDemographicExpansion: this.value.excludeDemographicExpansion,
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>
