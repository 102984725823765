<template>
  <div>
    <el-dialog
      title="您希望如何保存此受众群体"
      :visible="checkAudienceShow"
      width="1200px"
      append-to-body
      @close="close"
      @opened="openInit"
    >
      <p class="mb-2">此受众群体目前用于以下广告组或素材资源组：</p>
      <el-table
        :data="checkAudienceAdsList"
        style="width: 100%; overflow-y: scroll"
        height="300px"
      >
        <el-table-column
          prop="adGroupName"
          label="广告组（素材资源组）"
        >
        </el-table-column>
        <el-table-column
          prop="campaignName"
          label="广告系列"
        >
        </el-table-column>
      </el-table>
      <el-form
        ref="form"
        :model="form"
        label-width="160px"
      >
        <el-form-item label="您希望如何保存更改：">
          <el-radio-group
            v-model="form.type"
            @change="selectType"
          >
            <!-- <div> -->
            <el-radio label="save"
              >保存此受众群体
              <span class="text-xs text-[#999]"
                >（受众群体将更新，更改将应用于列出的广告组或素材资源组）</span
              ></el-radio
            >
            <!-- <p></p>
            </div> -->
            <!-- <div> -->
            <el-radio label="create"
              >将此受众群体另存为副本<span class="text-xs text-[#999]">（受众群体将保持不变）</span></el-radio
            >
            <!-- <p></p>
            </div> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label=""
          v-show="form.type == 'create'"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    checkAudienceShow: {
      type: Boolean,
      default: false,
    },
    checkAudienceAdsList: {
      type: Array,
      default: () => [],
    },
    audienceName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
      form: {
        type: 'save',
        name: '',
      },
    };
  },
  methods: {
    openInit() {},
    selectType(v) {
      console.log(v);
      if (v == 'create') {
        this.$nextTick(() => {
          this.form.name = this.audienceName + '_copy';
        });
      }
    },
    close() {
      this.$emit('update:checkAudienceShow', false);
    },
    sure() {
      if (this.form.type == 'save') {
        this.$emit('createAudience');
        this.close();
      } else {
        this.$emit('createAudience', { resourceName: '', name: this.form.name });
        this.close();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
