<template>
  <div>
    <el-select
      placeholder="选择受众"
      class="w-full"
      value-key="id"
      multiple
      filterable
      v-model="customAudiences"
      v-loading="customAudienceListLoading"
      :disabled="disabled"
      @change="selectAudience"
    >
      <el-option
        v-for="item in customAudienceList"
        :key="item.resourceName"
        :label="item.title"
        :value="item"
      >
      </el-option>
    </el-select>
    <div v-if="!disabled">
      <el-button
        type="text"
        @click="createSegmentAudience"
        >新建细分受众</el-button
      >
    </div>
    <CreateSegmentAudience
      :createAudienceDialogVisible.sync="createAudienceDialogVisible"
      :currentAccount="currentAccount"
      @getList="getCustomAudienceList"
    />
  </div>
</template>
<script>
import { listCustomerAudience } from '@/api/google/create.js';
import CreateSegmentAudience from '@/views/google/create/components/audience/createSegmentAudience/index.vue';
export default {
  props: {
    currentAccount: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
    defaultList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CreateSegmentAudience,
  },
  data() {
    return {
      createAudienceDialogVisible: false,
      customAudienceList: [],
      customAudienceListLoading: false,
    };
  },
  computed: {
    customAudiences: {
      get() {
        return this.value ? [...this.value] : [];
      },
      set() {},
    },
  },
  watch: {
    defaultList: {
      handler() {
        this.customAudienceList = this.defaultList;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 新建细分受众
    createSegmentAudience() {
      this.createAudienceDialogVisible = true;
    },
    // 获取自定义受众列表
    async getCustomAudienceList() {
      this.customAudienceListLoading = true;
      await listCustomerAudience({ accountId: this.currentAccount?.id })
        .then((res) => {
          if (res.code == 0) {
            this.customAudienceList = res.data;
          }
        })
        .finally(() => {
          this.customAudienceListLoading = false;
        });
    },
    selectAudience(v) {
      this.$emit('input', [...v]);
    },
  },
};
</script>
<style lang="scss" scoped></style>
